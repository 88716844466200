<template>
  <el-drawer
    title="菜单预览"
    :visible.sync="visible"
    direction="rtl"
    class="drawMenuDialog"
    append-to-body
  >
    <div class="menuDrap">
      <!-- 左侧列表 -->
      <div class="leftNav">
        <div
          class="leftItem"
          :class="currentCategory === item.categoryId ? 'active' : ''"
          v-for="(item, index) in flist"
          :key="index"
          @click="switchCate(item)"
        >
          {{ item.categoryName }}
        </div>
      </div>
      <!-- 右侧商品列表 -->
      <div class="rightCate">
        <div
          class="goodsList"
          v-for="(goodsItem, goodsIndex) in goodsList"
          :key="goodsIndex"
        >
          <div class="goodsPic" @click="openGoodsDetail(goodsItem)">
            <el-image class="icon" :src="goodsItem.imageUrl" fit="cover" />
          </div>
          <div class="goodsBlock">
            <div class="goodsTil" @click="openGoodsDetail(goodsItem)">
              {{ goodsItem.name }}
            </div>
            <div class="goodsPriceBox" @click="openGoodsDetail(goodsItem)">
              <div class="salesPrice">￥{{ goodsItem.price }}</div>
              <div class="factoryPrice" v-if="goodsItem.limitedNum !== 0">
                限购{{ goodsItem.limitedNum }}件
              </div>
            </div>
            <div
              class="tieChoose"
              v-if="goodsItem.isPackage"
              @click="openGoodsDetail(goodsItem)"
            >
              选规格
            </div>
          </div>
          <div class="timeGetList" @click="openGoodsDetail(goodsItem)">
            <div class="cutTime">
              截单时间:
              <div class="theTime">{{ dataForm.NewCutTime }}</div>
            </div>
            <div class="getTime">
              提货时间:
              <div class="theTime">{{ dataForm.NewdateTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 商品详情弹窗 -->
    <div class="mask-screengoods" @click="goodsHideModal" v-if="goodsVisible" />
    <div class="goodsBox" v-if="goodsVisible">
      <!-- 商品名称 -->
      <div class="goodsName">{{ goodsDetail && goodsDetail.name }}</div>
      <!-- 商品头部信息 -->
      <div class="goodsDetailPart">
        <!-- 商品图片 -->
        <div class="bannerList">
          <el-image :src="goodsDetail.imageUrl" fit="cover" />
        </div>
        <!-- 商品简介 -->
        <div class="goodsDescription">
          <div class="goodsDescTil">商品介绍：</div>
          <div
            class="goodsDescContent"
            v-if="goodsDetail.description.length > 0"
          >
            <div
              class="goodStaTion"
              v-for="(item, index) in goodsDetail.description"
              :key="index"
            >
              <div class="goodStaTionName">{{ item.key }}</div>
              <div class="goodStaTionValue">{{ item.value }}</div>
            </div>
          </div>
        </div>
        <!-- 商品搭配列表 -->
        <div class="goodsscrollPart">
          <div class="goodsTieAll" v-if="goodsDetail.isPackage">
            <div
              class="goodsTieList"
              v-for="(aitem, aindex) in goodsDetail.packages"
              :key="aindex"
            >
              <div class="goodsBigName">{{ aitem.name }}</div>
              <div class="nameList">
                <!--                {{ aitem }}-->
                <div
                  class="nameBlock"
                  :class="bitem.isChoose ? 'btnActive' : ''"
                  v-for="(bitem, bindex) in aitem.goodsList"
                  :key="bindex"
                  @click="chooseCate(bitem, aitem)"
                >
                  {{ bitem.name }}
                  {{ aitem.isCollocation ? '+' + bitem.price : '' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { dialogMixin, normal } from '@/mixins';
import moment from 'moment';
export default {
  mixins: [dialogMixin, normal],
  data() {
    return {
      dataForm: {
        cutOffTime: '2023-12-25 12:35:00',
        endTime: '16:00:00',
        freezerId: 1,
        isPresale: 1,
        orgPeriodCompanyFreezerId: 97,
        orgPeriodCompanyId: 31,
        periodId: 13,
        periodName: '下午茶',
        pickupDate: '2023-12-25',
        dateTime: '2023-12-25',
        spaceNum: null,
        startTime: '14:01:00',
        type: null,
        usedSpaceNum: 0,
        NewCutTime: null,
        NewdateTime: null,
      },
      allCateList: [],
      flist: [],
      goodsList: [],
      cartList: [],
      currentCategory: 48,
      cartVisible: false,
      goodsVisible: false,
      goodsDetail: {}, // 详细的商品详情
    };
  },
  methods: {
    init(id) {
      this.visible = true;
      this.$nextTick(() => {
        this.$http({
          url: `/tc/orgcompanygoods/show/${id}`,
          method: 'get',
        }).then(({ data }) => {
          if (data && data.code === 0) {
            if (data.list?.length) {
              this.allCateList = data.list; // 全数据
              this.flist = data.list.filter((i) => i.isShow);
              this.currentCategory = this.flist?.length
                ? this.flist[0].categoryId
                : null;
              this.goodsList = this.flist?.length ? this.flist[0].goods : [];
            } else {
              this.allCateList = []; // 全数据
              this.flist = [];
            }
          }
        });
      });
    },
    // 获取商品数量
    changeNumber(index, numberChange) {
      // 调起请求进行查询数据
      if (numberChange === 0) {
        this.cartVisible = false;
      }
    },
    // 切换左侧列表数据
    switchCate(event) {
      if (this.currentCategory === event.categoryId) {
        return false;
      }
      this.currentCategory = event.categoryId;
      this.goodsList = this.flist.filter(
        (i) => i.categoryId === event.categoryId,
      )[0].goods;
    },
    // 关闭商品弹层
    goodsHideModal() {
      this.goodsVisible = false;
    },
    // 打开当前页的商品详情弹层
    openGoodsDetail(row) {
      this.goodsDetail = this.goodsList.filter(
        (i) => i.categoryId === row.categoryId,
      )[0]; // 选中当前商品的详细信息

      // 判断当前商品存在且是套餐时
      if (this.goodsDetail?.isPackage) {
        // 首先，先遍历一边套餐的内容
        this.goodsDetail.packages.forEach((packItem) => {
          let PackageList = []; // 每次遍历都需要重新刷新每个数组的数据
          let packCategory = this.allCateList.filter(
            (s) => s.categoryId === packItem.categoryId,
          ); // 筛选出特定的类别
          if (packCategory[0].goods?.length) {
            // 同时判断当前的餐品类别下的商品商量大于0，即为有数据
            packCategory[0].goods.forEach((goodsItem) => {
              if (row.packageId === goodsItem.packageId) {
                packItem.goodsIds.forEach((sPackItem) => {
                  if (goodsItem.goodsId === sPackItem) {
                    // 遍历判断哪些商品可以显示的
                    goodsItem.isChoose = false;
                    PackageList.push(goodsItem); // 遍历筛选出需要显示商品，并存入
                  }
                });
              }
            });
            packItem.goodsList = PackageList;
          }
        });
      }
      this.goodsVisible = true;
    },
    // 点击获取对应选项数据
    chooseCate(row, lastRow) {
      let getGoodsCategoryId = row.categoryId; // 搭配的分类id
      let getGoodsCollect = row.isCollocation; // 是否 固定搭配(false) 还是 加钱搭配(true)
      let getGoodsLimitNum = parseInt(lastRow.limitedNum); // 搭配分类的最大数量值
      let getGoodsId = row.goodsId; // 搭配的商品id
      // 获取当前选项对应的分组信息
      let getHowPickList = this.goodsDetail.packages.filter(
        (item) => item.categoryId === getGoodsCategoryId,
      );
      // 获取有多少当前选项已经被选中了
      let howHasChoose = getHowPickList[0].goodsList.filter(
        (sitem) => sitem.isChoose,
      );

      // console.log(howHasChoose)
      // 先判断当前的搭配数量
      // 如果搭配数量限定为1时
      if (getGoodsLimitNum === 1) {
        // 如果没有选择搭配商品，可以直接转换当前的商品选择状态(isChoose)
        if (howHasChoose?.length) {
          // 如果已经有选择的商品，那么是直接切换商品选择状态
          this.goodsDetail.packages.forEach((packItem) => {
            if (packItem.categoryId === getGoodsCategoryId) {
              packItem.goodsList.forEach((goodsItem) => {
                // 先判断当前点击选择的套餐搭配商品，是否与之前选择的商品一样
                goodsItem.isChoose = goodsItem.goodsId === row.goodsId;
                this.$forceUpdate();
              });
            }
          });
        } else {
          this.goodsDetail.packages.forEach((packItem) => {
            if (packItem.categoryId === getGoodsCategoryId) {
              packItem.goodsList.forEach((goodsItem) => {
                goodsItem.isChoose = goodsItem.goodsId === getGoodsId;
                this.$forceUpdate();
              });
            }
          });
        }
      } else if (getGoodsLimitNum > 1) {
        // 如果搭配数量限定为超过1时
        // 先判断当前已经有选择的数据
        if (howHasChoose.length > 0) {
          // 循环已选择的数据，
          howHasChoose.forEach((chooseItem) => {
            // 判断当前点击的商品是否与已选择的商品是同一个
            if (chooseItem.goodsId === getGoodsId) {
              // 确定当前点击的商品是否与已选择的商品是同一个商品后，找到该商品，取消当前选择
              this.goodsDetail.packages.forEach((packItem) => {
                if (packItem.categoryId === getGoodsCategoryId) {
                  packItem.goodsList.forEach((goodsItem) => {
                    if (goodsItem.goodsId === getGoodsId) {
                      goodsItem.isChoose = false;
                      this.$forceUpdate();
                    }
                  });
                }
              });
            } else {
              // 当前点击的商品非已选择商品
              // 判断当前选择的选项数量超出限定选择的数量时，提出提示
              if (howHasChoose.length > getGoodsLimitNum) {
                this.$message.error(
                  `只能选择${getGoodsLimitNum}个商品进行搭配`,
                );
              } else if (howHasChoose.length === getGoodsLimitNum) {
                // 判断当前选择的选项数量等于限定选择的数量时，不作提示
              } else {
                // 判断当前选择的选项数量少于限定选择的数量时，当前点击的选项则为被选择
                this.goodsDetail.packages.forEach((packItem) => {
                  if (packItem.categoryId === getGoodsCategoryId) {
                    packItem.goodsList.forEach((goodsItem) => {
                      if (goodsItem.goodsId === getGoodsId) {
                        goodsItem.isChoose = true;
                        this.$forceUpdate();
                      }
                    });
                  }
                });
              }
            }
          });
        } else {
          // 没有已选择的数据，直接选择当前商品为已选择
          this.goodsDetail.packages.forEach((packItem) => {
            if (packItem.categoryId === getGoodsCategoryId) {
              // console.log(packItem)
              packItem.goodsList.forEach((goodsItem) => {
                if (goodsItem.goodsId === getGoodsId) {
                  goodsItem.isChoose = true;
                  this.$forceUpdate();
                }
              });
            }
          });
        }
      } else {
        // 循环已选择的数据，
        howHasChoose.forEach((chooseItem) => {
          // 判断当前点击的商品是否与已选择的商品是同一个
          if (chooseItem.goodsId === getGoodsId) {
            // 确定当前点击的商品是否与已选择的商品是同一个商品后，找到该商品，取消当前选择
            this.goodsDetail.packages.forEach((packItem) => {
              if (packItem.categoryId === getGoodsCategoryId) {
                packItem.goodsList.forEach((goodsItem) => {
                  if (goodsItem.goodsId === getGoodsId) {
                    goodsItem.isChoose = false;
                    this.$forceUpdate();
                  }
                });
              }
            });
          } else {
            // 当前点击的商品非已选择商品
            // 判断当前选择的选项数量超出限定选择的数量时，提出提示
            if (howHasChoose.length > getGoodsLimitNum) {
              this.$message.error(`只能选择${getGoodsLimitNum}个商品进行搭配`);
            } else if (howHasChoose.length === getGoodsLimitNum) {
              // 判断当前选择的选项数量等于限定选择的数量时，不作提示
            } else {
              // 判断当前选择的选项数量少于限定选择的数量时，当前点击的选项则为被选择
              this.goodsDetail.packages.forEach((packItem) => {
                if (packItem.categoryId === getGoodsCategoryId) {
                  packItem.goodsList.forEach((goodsItem) => {
                    if (goodsItem.goodsId === getGoodsId) {
                      goodsItem.isChoose = true;
                      this.$forceUpdate();
                    }
                  });
                }
              });
            }
          }
        });
      }
    },
    // 商品在列表时，加入购物车
    otherAddCart() {
      this.goodsVisible = true;
    },
    // 商品详情中，弹窗的加入购物车
    addCart() {
      this.goodsVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.drawMenuDialog::v-deep {
  .el-drawer__container {
    .el-drawer {
      width: 35% !important;
      outline: none;
      .el-drawer__header {
      }
      .el-drawer__body {
        border: 3px #000 solid;
        display: flex;
        flex-wrap: wrap;
        .menuDrap {
          flex: 1;
          width: 100%;
          background: #fff;
          display: flex;
          flex-wrap: wrap;
          margin-top: 0; /* 230rpx */
          .leftNav {
            width: 120px;
            height: 100%;
            .leftItem {
              text-align: center;
              line-height: 50px;
              width: 120px;
              height: 50px;
              color: #333;
              cursor: pointer;
              font-size: 14px;
              border-left: 6px solid #fff;
              border-bottom: 2px #efefef solid;
            }
            .active {
              color: #e41f19;
              font-size: 18px;
              border-left: 6px solid #e41f19;
            }
          }
          .rightCate {
            border-left: 2px solid #efefef;
            flex: 1;
            padding: 0;
            height: 100%;
            .goodsList {
              position: relative;
              border-bottom: 1px #efefef solid;
              overflow: hidden;
              padding: 10px 0 10px 10px;
              display: flex;
              flex-wrap: wrap;
              .goodsPic {
                width: 60px;
                height: 58px;
                position: relative;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                .el-image {
                  height: 58px;
                  width: 100%;
                  display: block;
                  margin: 0 auto;
                }
              }
              .goodsBlock {
                flex: 1;
                position: relative;
                padding: 0 0 0 10px;
                .goodsTil {
                  display: -webkit-box;
                  overflow: hidden;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  font-size: 15px;
                  color: #333;
                  height: 30px;
                  line-height: 1.2;
                  word-break: break-all;
                }
                .goodsPriceBox {
                  position: relative;
                  display: block;
                  padding: 5px 0 0;
                  .salesPrice,
                  .factoryPrice {
                    font-size: 14px;
                    color: #f00;
                    line-height: 1.8;
                    margin: 0 8px 0 0;
                    display: inline-block;
                  }
                  .factoryPrice {
                    color: #333;
                    font-size: 12px;
                  }
                }
                .tieChoose {
                  position: absolute;
                  bottom: 3px;
                  right: 5px;
                  z-index: 9;
                  cursor: pointer;
                  border: 1px transparent solid;
                  background-color: #f00;
                  color: #fff;
                  text-align: center;
                  padding: 5px 10px;
                  border-radius: 13px;
                  font-size: 13px;
                }
              }
              .timeGetList {
                position: relative;
                border-top: 1px #efefef solid;
                overflow: hidden;
                padding: 8px 0;
                margin: 10px 0 0;
                width: 100%;
                .cutTime,
                .getTime {
                  font-size: 13px;
                  line-height: 1.8;
                  color: #000;
                  .theTime {
                    color: #f00;
                    display: inline-block;
                  }
                }
              }
            }
          }
        }

        /* 商品弹层 */
        .mask-screengoods {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: #000;
          opacity: 0.5;
          overflow: hidden;
          z-index: 9997;
        }
        .goodsBox {
          border-radius: 6px;
          width: 80%;
          overflow-x: hidden;
          overflow-y: auto;
          position: absolute;
          top: 10vh;
          left: 10%;
          z-index: 9998;
          background: #fff;
          height: 70vh;
          padding: 0;
          .goodsName {
            font-size: 18px;
            color: #000;
            padding: 8px 15px;
            border-bottom: 1px #efefef solid;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: #fff;
            z-index: 99;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .goodsDetailPart {
            position: relative;
            height: 70vh;
            overflow-x: hidden;
            overflow-y: auto;
            padding: 44px 0 40px;
            .bannerList {
              position: relative;
              height: 120px;
              overflow: hidden;
              border-bottom: 1px #efefef solid;
              padding: 0;
              img {
                height: 120px;
                width: 100%;
              }
            }
            .goodsDescription {
              position: relative;
              overflow: hidden;
              border-bottom: 1px #efefef solid;
              padding: 4px 8px;
              .goodsDescTil {
                display: block;
                font-size: 14px;
                color: #333;
                line-height: 1.6;
                position: relative;
              }
              .goodsDescContent {
                display: block;
                font-size: 13px;
                color: #999;
                line-height: 1.6;
                position: relative;
                text-indent: 2em;
                border: 1px #efefef solid;
                border-bottom: none;
              }
            }
            .goodStaTion {
              position: relative;
              display: flex;
              flex-wrap: wrap;
              border-bottom: 1px #efefef solid;
              align-items: center;
              justify-content: center;
              .goodStaTionName {
                width: 80px;
                color: #000;
              }
              .goodStaTionValue {
                flex: 1;
                font-size: 13px;
                line-height: 1.4;
                color: #999;
                border-left: 1px #efefef solid;
                padding: 0 10px;
                text-indent: 0;
              }
            }
          }
          .goodsscrollPart {
            /* height: calc(70vh - 100rpx - 52rpx - 36rpx - 2px); */
            position: relative;
            .goodsTieAll {
              position: relative;
              overflow: hidden;
              padding: 8px 10px;
              .goodsTieList {
                margin: 0 0 10px;
                position: relative;
                overflow: hidden;
                .goodsBigName {
                  display: block;
                  font-size: 16px;
                  color: #333;
                  line-height: 1.6;
                  position: relative;
                  border-bottom: 1px #efefef solid;
                }
                .nameList {
                  display: flex;
                  flex-wrap: wrap;
                  position: relative;
                  .nameBlock {
                    display: inline-block;
                    border: 1px #f00 solid;
                    font-size: 15px;
                    margin: 10px 10px 0 0;
                    padding: 8px 10px;
                    border-radius: 4px;
                    color: #333;
                    background-color: #fff;
                  }
                }
              }
            }
          }
          .btnList {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 99;
            overflow: hidden;
            border-top: 1px #efefef solid;
            padding: 10px 15px;
            height: 60px;
            background-color: #fff;
            .btn {
              background-color: #f00;
              color: #fff;
              padding: 8px 15px;
              font-size: 16px;
              border-radius: 4px;
              float: right;
            }
          }
        }

        .btnActive {
          background-color: #f00 !important;
          color: #fff !important;
        }
      }
    }
  }
}
</style>
