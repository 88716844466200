<template>
  <div class="warehouse-double-list-page">
    <el-form ref="dataForm" inline class="bysearchForm">
      <div class="searchLine">
        <el-form-item prop="listId" class="typeInput" label="左侧选择类型：">
          <el-select
            v-model="listId"
            @change="getDataList(1)"
            class="sideTopSelect"
          >
            <el-option
              v-for="(item, index) in listSelections"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </div>
      <div class="searchLine">
        <el-form-item
          prop="companyId"
          class="typeInput"
          v-if="listId === 2"
          label="公司"
        >
          <el-select
            v-model="dataForm.companyId"
            placeholder="公司"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          prop="orgId"
          class="typeInput"
          v-if="listId === 1"
          label="配餐公司"
        >
          <el-select
            v-model="dataForm.orgId"
            placeholder="配餐公司"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in orgList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="periodId" class="typeInput" label="时段">
          <el-select
            v-model="dataForm.periodId"
            placeholder="时段"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in periodList"
              :key="index"
              :label="item.withTimeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="state" label="状态">
          <el-select
            v-model="dataForm.state"
            placeholder="状态"
            multiple
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in stateList"
              :key="index"
              :label="item.state"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="weeks" class="typeInput" label="星期">
          <el-select
            v-model="dataForm.weeks"
            placeholder="星期"
            multiple
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in weeksList"
              :key="index"
              :label="item.week"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="typeInput">
          <el-button @click="getDetailList()">查询</el-button>
          <el-button
            v-if="isAuth('tc:orgPeriodCompany:save')"
            type="primary"
            @click="$dialog('addOrUpdate')"
            >新增</el-button
          >
          <el-button
            v-if="isAuth('tc:orgPeriodCompany:delete')"
            type="danger"
            @click="deleteHandle()"
            :disabled="detailListSelections.length <= 0"
            >批量删除</el-button
          >
          <el-button
            v-if="isAuth('tc:orgcompanygoods:update')"
            type="primary"
            @click="updateGoods(null)"
            :disabled="detailListSelections.length <= 0"
            >批量上架商品</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <div class="content-container">
      <div class="profit-loss-list-container" style="flex: 0 0 25%">
        <div class="table-container">
          <el-table
            height="calc(100vh - 210px)"
            :data="dataList"
            ref="dataList"
            border
            :row-class-name="tableRowClassName"
            @selection-change="$selectionChange($event, 'dataList')"
            @row-click="
              $clickRow(
                $event,
                'detailList',
                getDetailList($event),
                colorChange($event),
              )
            "
            @select="$select(arguments, 'dataList')"
            @select-all="$clearSelection('dataList')"
          >
            <el-table-column
              prop="name"
              header-align="center"
              align="center"
              :label="listName"
            />
          </el-table>
        </div>
        <div class="pagination-container">
          <el-pagination
            background
            @size-change="$sizeChange($event, 'dataList')"
            @current-change="$currentChange($event, 'dataList')"
            :current-page="dataListIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="dataListSize"
            :total="dataListCount"
            layout="total, prev, pager, next"
          />
        </div>
      </div>
      <div class="divider-border" />
      <div class="profit-loss-detail-container">
        <div class="table-container">
          <el-table
            :data="detailList"
            border
            stripe
            ref="detailList"
            v-loading="detailListLoading"
            @row-click="$clickRow($event, 'detailList')"
            @selection-change="$selectionChange($event, 'detailList')"
            style="width: 100%"
            @select-all="handleSelectAll"
          >
            <el-table-column
              type="selection"
              header-align="center"
              align="center"
              width="50"
            />
            <el-table-column
              prop="orgName"
              header-align="center"
              align="center"
              label="供应商名称"
              v-if="listId === 1"
            />
            <el-table-column
              prop="companyName"
              header-align="center"
              align="center"
              label="公司"
              v-if="listId === 2"
            />
            <el-table-column
              prop="periodWithTimeName"
              header-align="center"
              align="center"
              label="时段"
            />
            <el-table-column
              prop="weeks"
              header-align="center"
              align="center"
              label="星期"
            />
            <el-table-column
              prop="state"
              header-align="center"
              align="center"
              width="120"
              label="状态"
            >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.state === 0" size="small"
                  >未生效</el-tag
                >
                <el-tag v-else-if="scope.row.state === 1" size="small"
                  >生效中</el-tag
                >
                <el-tag v-else-if="scope.row.state === 2" size="small"
                  >已失效</el-tag
                >
                <el-tag v-else-if="scope.row.state === 3" size="small"
                  >已终止</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="createdAt"
              header-align="center"
              align="center"
              label="创建日期"
            />

            <el-table-column
              prop="createdBy"
              header-align="center"
              align="center"
              label="创建者"
            />
            <el-table-column
              header-align="center"
              align="center"
              width="280"
              label="操作"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="isAuth('tc:orgPeriodCompany:info')"
                  type="text"
                  size="small"
                  @click="$dialog('addOrUpdate', scope.row.id, true)"
                  >查看</el-button
                >
                <el-button
                  v-if="
                    isAuth('tc:orgPeriodCompany:update') &&
                    scope.row.state !== 2 &&
                    scope.row.state !== 3
                  "
                  type="text"
                  size="small"
                  @click="$dialog('addOrUpdate', scope.row.id)"
                  >修改</el-button
                >
                <el-button
                  v-if="isAuth('tc:orgPeriodCompany:update')"
                  type="text"
                  size="small"
                  @click="$dialog('companyFreezer', scope.row.id)"
                  >仓位分配</el-button
                >
                <el-button
                  v-if="isAuth('tc:orgPeriodCompany:delete')"
                  type="text"
                  size="small"
                  @click="deleteHandle(scope.row.id)"
                  >删除</el-button
                >
                <el-button
                  v-if="isAuth('tc:orgcompanygoods:query')"
                  type="text"
                  size="small"
                  @click="updateGoods(scope.row)"
                  >自由预售上架</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  v-if="isAuth('tc:orgcompanyadvancegoods:query')"
                  @click="updateAdvanceGoods(scope.row)"
                  >限定预售上架</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  v-if="isAuth('tc:orgcompanygoods:show')"
                  @click="$dialog('previewdialog', scope.row.id)"
                  >预览菜单弹窗</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination-container">
          <el-pagination
            background
            @size-change="$sizeChange($event, 'detailList')"
            @current-change="$currentChange($event, 'detailList')"
            :current-page="detailListIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="detailListSize"
            :total="detailListCount"
            layout="total, sizes, prev, pager, next, jumper"
          />
        </div>
      </div>
    </div>

    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @success="getDetailList(null, 1)"
    />
    <company-freezer
      v-if="companyFreezerVisible"
      ref="companyFreezer"
      @success="getDetailList(null, 1)"
    />
    <company-goods-on-shelves
      v-if="companyGoodsOnShelvesVisible"
      ref="companyGoodsOnShelves"
      @success="getDetailList(null, 1)"
    />
    <company-advance-goods-on-shelves
      v-if="advanceGoodsOnShelvesVisible"
      ref="advanceGoodsOnShelves"
      @success="getDetailList(null, 1)"
    />
    <!-- 预览菜单弹层 -->
    <preview-dialog v-if="previewdialogVisible" ref="previewdialog" />
  </div>
</template>

<script>
import { threeListMixin, listMixin, normal } from '@/mixins';
import AddOrUpdate from './org-period-company-add-or-update';
import CompanyGoodsOnShelves from './company-goods-on-shelves';
import CompanyFreezer from './org-period-company-freezer';
import CompanyAdvanceGoodsOnShelves from './company-advance-goods-on-shelves';
import { getCompanyList, getPeriodList, getOrgList } from '@/utils/options.js';
import previewDialog from '@/components/menuPreview';
export default {
  mixins: [threeListMixin, listMixin, normal],
  data() {
    return {
      dataForm: {
        orgId: '',
        companyId: '',
        periodId: '',
        state: [0, 1],
        weeks: [],
      },
      ids: '',
      firstData: '',
      companyOrOrg: '',
      periodTable: '',
      orgIds: [],
      chooseIndex: 0,
      dataList: [],
      detailList: [],
      addOrUpdateVisible: false,
      companyFreezerVisible: false,
      companyGoodsOnShelvesVisible: false,
      advanceGoodsOnShelvesVisible: false,
      previewdialogVisible: false, // 预览弹窗
      dataListSelections: [],
      detailListSelections: [],
      companyList: [],
      orgList: [],
      periodList: [],
      listId: 1,
      listName: '',
      listSelections: [
        { id: 1, name: '公司列表' },
        { id: 2, name: '供应商列表' },
      ],
      stateList: [
        { id: 0, state: '未生效' },
        { id: 1, state: '生效中' },
        { id: 2, state: '已失效' },
        { id: 3, state: '已终止' },
      ],
      weeksList: [
        { id: 1, week: '一' },
        { id: 2, week: '二' },
        { id: 3, week: '三' },
        { id: 4, week: '四' },
        { id: 5, week: '五' },
        { id: 6, week: '六' },
        { id: 7, week: '日' },
      ],
    };
  },
  components: {
    AddOrUpdate,
    CompanyGoodsOnShelves,
    CompanyAdvanceGoodsOnShelves,
    CompanyFreezer,
    previewDialog,
  },
  activated() {
    this.getDataList();
  },
  created() {
    this.getPeriodList();
    this.getOrgList();
    this.getCompanyList();
  },
  methods: {
    tableRowClassName({ row }) {
      // this.
      if (row.id === this.chooseIndex) {
        return 'danger-row';
      } else {
        return '';
      }
    },
    colorChange(row) {
      this.chooseIndex = row.id;
    },
    getDataList(type) {
      if (type === 1) {
        this.dataForm.companyId = null;
        this.dataForm.orgId = null;
      }
      if (this.listId === 1) {
        this.getCompanyPage();
      } else {
        this.getOrgPage();
      }
    },
    getCompanyPage() {
      this.$api({
        url: '/tc/company/query',
        params: {
          page: this.dataListIndex,
          limit: this.dataListSize,
        },
        after: (data) => {
          if (data && data.code === 0) {
            this.dataList = data.list.records;
            this.dataListCount = data.list.total;
            this.listName = this.listSelections[0].name;
            this.dataForm.companyId = data.list.records[0].id;
            this.getDetailList(null, 1);
          } else {
            this.dataList = [];
            this.dataListCount = 0;
          }
        },
      });
    },
    getOrgPage() {
      this.$http({
        url: `/cc/org/page`,
        method: 'get',
        params: {
          type: 0,
          isUse: 1,
          page: this.dataListIndex,
          limit: this.dataListSize,
        },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.dataList = data.data.items;
          this.dataListCount = data.data.total;
          this.listName = this.listSelections[1].name;
          this.dataForm.orgId = data.data.items[0].id;
          this.getDetailList(null, 1);
        } else {
          this.dataList = [];
          this.dataListCount = 0;
        }
      });
    },
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },

    getCompanyList() {
      getCompanyList().then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },
    // 获取数据列表
    getDetailList(row, page) {
      if (this.listId === 1) {
        this.dataForm.companyId = row ? row.id : this.dataForm.companyId;
      } else {
        this.dataForm.orgId = row ? row.id : this.dataForm.orgId;
      }
      this.detailListIndex = page ? page : this.detailListIndex;
      this.$api({
        url: '/tc/orgPeriodCompany/list',
        params: {
          page: this.detailListIndex,
          limit: this.detailListSize,
          ...this.dataForm,
          states: this.dataForm.state.join(','),
          weeks: this.dataForm.weeks.join(','),
        },
        after: (data) => {
          if (data && data.code === 0) {
            this.detailList = data.page.records;
            this.detailListCount = data.page.total;
          } else {
            this.dataList = [];
            this.detailListCount = 0;
          }
        },
      });
    },
    // 删除
    deleteHandle(id) {
      let ids = id
        ? [id]
        : this.detailListSelections.map((item) => {
            return item.id;
          });
      this.$handleDelete({
        tip: `是否确认对这些公司进行删除操作?`,
        url: '/tc/orgPeriodCompany/delete',
        data: ids,
        after: () => {
          this.getDetailList();
        },
      });
    },
    // 批量上架商品
    updateGoods(row) {
      this.getPeriodTable(row);
      if (this.orgIds?.length) {
        this.$message.error(`请选择相同的供应商进行批量操作`);
      } else {
        this.$dialog(
          'companyGoodsOnShelves',
          this.firstData,
          this.ids,
          this.companyOrOrg,
          this.periodTable,
        );
      }
    },
    updateAdvanceGoods(row) {
      this.getPeriodTable(row);
      if (this.orgIds?.length) {
        this.$message.error(`请选择相同的供应商进行批量操作`);
      } else {
        this.$dialog(
          'advanceGoodsOnShelves',
          this.firstData,
          this.ids,
          this.companyOrOrg,
          this.periodTable,
        );
      }
    },
    getPeriodTable(row) {
      this.ids = row
        ? row.id
        : this.detailListSelections
            .map((item) => {
              return item.id;
            })
            .join(',');
      let data = [];
      if (row) {
        data.push(row);
      } else {
        data = this.detailListSelections;
      }
      this.firstData = data[0].orgId;
      this.periodTable = data.reduce((groups, item) => {
        const key = item.periodName;

        // 如果group对象中还没有这个periodId作为键，则初始化为空数组
        if (!groups[key]) {
          groups[key] = [];
        }

        // 将格式化后的字符串加入到对应的periodId分组中
        groups[key].push(item.companyName);

        return groups;
      }, {});
      this.companyOrOrg = data[0].orgName;
      if (this.listId === 1) {
        this.periodTable = data.reduce((groups, item) => {
          const key = item.periodName;

          // 如果group对象中还没有这个periodId作为键，则初始化为空数组
          if (!groups[key]) {
            groups[key] = [];
          }

          // 将格式化后的字符串加入到对应的periodId分组中
          groups[key].push(item.orgName);

          return groups;
        }, {});
        this.companyOrOrg = data[0].companyName;
      }
      this.orgIds = data
        .map((item) => {
          return item.orgId;
        })
        .filter((item) => this.firstData !== item);
    },
    getPeriodList() {
      getPeriodList(1).then(({ data }) => {
        if (data && data.status === 0) {
          this.periodList = data.data.items;
        }
      });
    },
  },
};
</script>
